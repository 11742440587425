import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { Card } from "clutch/src/Card/Card.jsx";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import {
  NUMBER_OF_LEADERBOARDS_ON_HOMEPAGE,
  TOP_REGIONS,
} from "@/game-val/constants.mjs";
import { getLeaderboardData } from "@/game-val/leaderboard-utils.mjs";
import { getProfileIcon } from "@/game-val/static.mjs";
import { getNameTag } from "@/game-val/utils.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function HomeLeaderboard() {
  const { t } = useTranslation();

  const {
    val: { leaderboard = {}, content },
  } = useSnapshot(readState);

  const leaderboards = useMemo(() => {
    const regions = TOP_REGIONS.slice(0, NUMBER_OF_LEADERBOARDS_ON_HOMEPAGE);

    return regions.map((region) =>
      getLeaderboardData(
        leaderboard,
        new URLSearchParams(`region=${region}`),
        content,
      ),
    );
  }, [content, leaderboard]);

  const img = getProfileIcon();

  const isLoading = !Object.keys(leaderboard).length;

  if (isLoading) {
    return (
      <Container className="flex gap-2 justify-center">
        <Card
          className="leader-card loading-row"
          padding={0}
          style={{ height: 212 }}
        />
        <Card
          className="leader-card loading-row"
          padding={0}
          style={{ height: 212 }}
        />
        <Card
          className="leader-card loading-row"
          padding={0}
          style={{ height: 212 }}
        />
      </Container>
    );
  }

  return (
    <Container>
      <ol className="leaders">
        {leaderboards.map((ls, i) => {
          const l = ls?.find((entry) => entry.gameName && entry.tagLine); // Skip players missing names
          if (!l) return null;
          return (
            <PlayerInfo key={`${l.region}-${l.position}`}>
              <Card className="leader-card" padding={0}>
                <img
                  src={img}
                  width="56"
                  height="56"
                  className="backdrop-img"
                />
                <a
                  className="info"
                  href={`/valorant/profile/${getNameTag(
                    l.gameName,
                    l.tagLine,
                  )}`}
                >
                  <p className="top-rating type-caption--semi">
                    {t("common:topRegion", "Top {{region}}", {
                      region: l.region.toUpperCase(),
                    })}
                  </p>
                  <img src={img} width="56" height="56" className="avatar" />
                  <p className="name type-subtitle2">{l.gameName}</p>
                  <p className="shade1 type-subtitle2">
                    {t("val:rankedRating", "{{points}} RR", {
                      points: l.rating,
                    })}
                  </p>
                </a>
                <Button
                  href={`/valorant/leaderboards/official?region=${l.region}`}
                  className="leaderboard-link"
                >
                  {t("common:leaderboard", "Leaderboard")}
                </Button>
              </Card>
              <div className="position type-button-text">#{i + 1}</div>
            </PlayerInfo>
          );
        })}
      </ol>
    </Container>
  );
}

const Container = styled("div")`
  container-type: inline-size;
  container-name: home-leaderboard;
  flex: 1;

  ${tablet} {
    width: 100%;
  }

  .leaders {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sp-2);

    @container home-leaderboard (inline-size <= 450px) {
      gap: var(--sp-1);
    }
  }

  section {
    width: 148px;

    @container home-leaderboard (inline-size <= 450px) {
      width: 110px;
    }
  }

  ${mobile} {
    section:nth-child(3) {
      display: none;
    }
  }
`;

const PlayerInfo = styled("li")`
  position: relative;

  > * {
    position: relative;
  }

  .leader-card:has(.info:hover) .backdrop-img {
    opacity: 0.15;
    filter: blur(23px) brightness(1.25);
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--sp-2);
    padding: var(--sp-5) 0 var(--sp-3);

    > * {
      position: relative;
    }
  }

  .backdrop-img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: auto;
    aspect-ratio: 1;
    translate: -50% -50%;
    opacity: 0.05;
    filter: blur(23px);
    pointer-events: none;
    transition:
      opacity var(--transition),
      fitler var(--transition);
  }

  .position {
    position: absolute;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -14px;
    left: 50%;
    translate: -50% 0;
    width: 40px;
    border-radius: var(--br);
    background: var(--subscriber-gradient-15);
    border: var(--sp-px) solid #4b4235;
    color: var(--subscriber);
    backdrop-filter: blur(var(--sp-5));
  }

  .top-rating {
    color: var(--subscriber);
  }

  .avatar {
    width: var(--sp-14);
    height: var(--sp-14);
    border-radius: 50%;

    @container home-leaderboard (inline-size <= 450px) {
      width: var(--sp-10);
      height: var(--sp-10);
    }
  }

  .name,
  .top-rating {
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @container home-leaderboard (inline-size <= 450px) {
      width: 10ch;
    }
  }

  .leaderboard-link {
    width: 100%;
    color: var(--shade0);
    background: var(--shade6);
    border-radius: 0 0 var(--card-radii) var(--card-radii);
  }
`;
